<template>
    <Breadcrumbs
        :items="breadcrumbs"
        class="my-1 px-2"
        link-mode="inertia"
        v-if="breadcrumbs.length > 1"
    />
    <Head>
        {{ breadcrumbsSchema }}
    </Head>
</template>

<script setup>
import { Breadcrumbs } from "@babestationui/vue";

import { usePage, Head } from "@inertiajs/vue3";
import { computed } from "vue";

const breadcrumbs = computed(() => usePage().props.breadcrumbs);

const breadcrumbsSchema = computed(() => {
    return usePage().props.breadcrumbsSchema;
});
</script>
