<template>
    <div class="2xl:container mx-auto bg-white">
        <Head>
            <title>{{ title }}</title>
            <meta name="description" :content="description" />
        </Head>
        <NotificationsContainer />

        <div class="2xl:container mx-auto min-h-screen relative">
            <div class="sticky top-0 z-50">
                <NavbarDesktopBase class="hidden lg:block" height="h-18">
                    <template v-slot:logo>
                        <img
                            width="110"
                            height="23.5"
                            src="https://cdn.babestation.tv/logos/bsx-logo.png"
                        />
                    </template>
                    <template v-slot:nav-actions>
                        <div
                            class="flex lg:grid lg:grid-cols-8 gap-4 w-full px-4"
                        >
                            <NavbarDesktopItem
                                href="/"
                                class="text-center uppercase font-bold"
                            >
                                <div class="flex items-center flex-col">
                                    <Home :width="6" :height="6" />
                                    <div>Home</div>
                                </div>
                            </NavbarDesktopItem>
                            <NavbarDesktopItem
                                href="/updates"
                                mode="inertia"
                                class="text-center uppercase font-bold"
                            >
                                Member's <br />
                                Updates
                            </NavbarDesktopItem>
                            <NavbarDesktopItem
                                href="/models"
                                mode="inertia"
                                class="text-center uppercase font-bold"
                            >
                                BSX <br />
                                Models
                            </NavbarDesktopItem>
                            <NavbarDesktopItem
                                href="https://babestation.tv/?oac=bsx"
                                class="text-center uppercase font-bold"
                                mode="html"
                            >
                                Live <br />
                                HD Cams
                            </NavbarDesktopItem>

                            <NavbarDesktopItem
                                href="https://bsx.tv"
                                class="text-center uppercase font-bold"
                            >
                                Get <br />
                                Tv Pin
                            </NavbarDesktopItem>
                            <NavbarDesktopItem
                                href="https://www.babestation.tv/bsx-live"
                                class="text-center uppercase font-bold"
                            >
                                Watch <br />BSX Live
                            </NavbarDesktopItem>
                            <NavbarDesktopItem
                                href="https://babestationcams.com"
                                class="text-center uppercase font-bold"
                            >
                                Babestation <br />Cams
                            </NavbarDesktopItem>

                            <NavbarDesktopItem
                                href="#"
                                class="text-center uppercase font-bold flex items-center justify-center"
                                :hasDropdown="true"
                            >
                                More
                                <template #hoveredContent>
                                    <div
                                        class="w-fit divide-y items-center divide-gray-700 absolute top-11 right-1/2 left-1/2 -translate-x-1/2 bg-dark-brand px-2 py-2 flex flex-col justify-center"
                                    >
                                        <NavbarDesktopItem
                                            href="https://www.babestation.tv/porn-games"
                                            class="mx-4 md:mx-1 pb-2 flex justify-center items-end"
                                        >
                                            Sex Games
                                            <ExternalLink
                                                :height="3"
                                                :width="3"
                                                class="mb-2 ml-1 inline"
                                            ></ExternalLink>
                                        </NavbarDesktopItem>

                                        <NavbarDesktopItem
                                            href="/schedule"
                                            class="mx-4 md:mx-1 pb-2 flex justify-center items-end"
                                            mode="inertia"
                                        >
                                            Schedule
                                        </NavbarDesktopItem>
                                    </div>
                                </template>
                            </NavbarDesktopItem>
                        </div>
                    </template>
                    <template v-slot:account-actions>
                        <div class="flex items-center">
                            <Link
                                href="https://www.babestation.tv/vip?from=bsx"
                                class="mx-2"
                                mode="html"
                            >
                                <div
                                    class="text-gray-200 font-bold bg-yellow-600 bg-opacity-75 transition-all rounded-full px-6 py-2 hover:bg-opacity-50 whitespace-nowrap text-sm text-center"
                                >
                                    VIP SIGNUP
                                </div>
                            </Link>
                            <Link
                                href="https://www.babestation.tv/login?from=bsx"
                                mode="html"
                            >
                                <div
                                    class="font-bold text-white text-sm flex px-2 py-2 hover:text-pink-brand"
                                >
                                    VIP LOGIN
                                </div>
                            </Link>
                        </div>
                    </template>
                </NavbarDesktopBase>
            </div>
            <div class="lg:hidden">
                <NavbarMobileBase ref="mobileNavbar" :search-enabled="false">
                    <template v-slot:logo>
                        <img
                            width="110"
                            height="23.5"
                            src="https://cdn.babestation.tv/logos/bsx-logo.png"
                            class="mx-auto"
                        />
                    </template>
                    <template v-slot:top-menu>
                        <div class="flex items-center">
                            <Link
                                href="https://www.babestation.tv/vip?from=bsx"
                                class="mx-2"
                                mode="html"
                            >
                                <div
                                    class="text-gray-200 font-bold bg-yellow-600 bg-opacity-75 transition-all rounded-full px-6 py-2 hover:bg-opacity-50 whitespace-nowrap text-sm text-center"
                                >
                                    VIP SIGNUP
                                </div>
                            </Link>
                            <Link
                                href="https://www.babestation.tv/login?from=bsx"
                                mode="html"
                            >
                                <div
                                    class="font-bold text-white text-sm flex px-2 py-2 hover:text-pink-brand"
                                >
                                    VIP LOGIN
                                </div>
                            </Link>
                        </div>
                    </template>
                    <template v-slot:nav-items>
                        <div class="divide-y divide-gray-800">
                            <NavbarMobileSection class="mt-4">
                                Main
                            </NavbarMobileSection>
                            <NavbarMobileItem
                                href="/"
                                @finish="mobileNavbar.toggleMenu"
                            >
                                <template v-slot:icon>
                                    <Home :height="5" :width="5" />
                                </template>
                                <template v-slot:text>
                                    BabestationX Home</template
                                >
                            </NavbarMobileItem>

                            <NavbarMobileItem
                                href="/updates"
                                mode="inertia"
                                @finish="mobileNavbar.toggleMenu"
                            >
                                <template v-slot:icon>
                                    <Fire :height="5" :width="5"> </Fire>
                                </template>
                                <template v-slot:text>
                                    Member's Updates
                                </template>
                            </NavbarMobileItem>

                            <NavbarMobileItem
                                href="/models"
                                mode="inertia"
                                @finish="mobileNavbar.toggleMenu"
                            >
                                <template v-slot:icon>
                                    <Fire :height="5" :width="5"> </Fire>
                                </template>
                                <template v-slot:text> BSX Models </template>
                            </NavbarMobileItem>

                            <NavbarMobileItem
                                href="https://www.babestation.tv/?oac=bsx"
                                @finish="mobileNavbar.toggleMenu"
                            >
                                <template v-slot:icon> LIVE </template>
                                <template v-slot:text> Live HD Cams </template>
                            </NavbarMobileItem>

                            <NavbarMobileItem
                                href="https://bsx.tv"
                                @finish="mobileNavbar.toggleMenu"
                            >
                                <template v-slot:icon>
                                    <LockOpen :width="5" :height="5" />
                                </template>
                                <template v-slot:text> Get TV Pin</template>
                            </NavbarMobileItem>

                            <NavbarMobileItem
                                href="https://babestationcams.com"
                                model="html"
                            >
                                <template v-slot:icon>
                                    <VideoCamera :width="5" :height="5" />
                                </template>
                                <template v-slot:text>
                                    Babestation Cams</template
                                >
                            </NavbarMobileItem>

                            <NavbarMobileItem
                                href="https://www.babestation.tv/bsx-live"
                                @finish="mobileNavbar.toggleMenu"
                            >
                                <template v-slot:icon>
                                    <Tv :width="5" :height="5" />
                                </template>
                                <template v-slot:text> Watch BSX Live</template>
                            </NavbarMobileItem>

                            <NavbarMobileItem
                                href="https://www.babestation.tv/phone-sex"
                                @finish="mobileNavbar.toggleMenu"
                            >
                                <template v-slot:icon>
                                    <Phone :width="5" :height="5" />
                                </template>
                                <template v-slot:text> Phone Sex</template>
                            </NavbarMobileItem>
                        </div>
                    </template>
                </NavbarMobileBase>
            </div>

            <main class="pt-12 lg:py-0 min-h-screen">
                <Breadcrumbs />
                <slot></slot>
            </main>

            <div class="z-50 relative">
                <Footer></Footer>
            </div>
        </div>
    </div>
</template>

<script setup>
import Footer from "../components/layout/Footer.vue";
import {
    NavbarDesktopBase,
    NavbarDesktopItem,
    NavbarMobileBase,
    NavbarMobileSection,
    NavbarMobileItem,
    NotificationsContainer,
    Link,
    useNotify
} from "@babestationui/vue";

import {
    Fire,
    VideoCamera,
    Home,
    LockOpen,
    Tv,
    ExternalLink,
} from "@babestationui/vue/icons";

import { computed, ref, onMounted } from "vue";
import { usePage, Head } from "@inertiajs/vue3";
import { Phone } from "@babestationui/vue/icons";
import Breadcrumbs from "../components/layout/Breadcrumbs.vue";

const mobileNavbar = ref();

const title = computed(() => usePage().props.title);
const description = computed(() => usePage().props.description);
const initialNotificaton = computed(() => usePage().props.notify)

const { notify } = useNotify()

onMounted(async () => {

    if (initialNotificaton.value) {
        notify(initialNotificaton.value)
    }
})

</script>
